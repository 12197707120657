import { Tooltip } from '@mui/material'
import { ArrowLeft, Check, ChevronDown, Edit, PlusCircle, Trash2 } from 'feather-icons-react/build/IconComponents'
import React, { useState } from 'react'
import { CalendarFill } from 'react-bootstrap-icons'
import DataTable from 'react-data-table-component'
import DatePicker from "react-datepicker"
import { Link } from 'react-router-dom'
import { GenerateSlugName } from '../../controllers/GenerateSlugName'
import ImageUploader from '../../controllers/ImageUploader'
import TextEditor from '../../controllers/TextEditor'
import Breadcrumbs from '../../controllers/breadCrumbs'
import AddAppPlan from './AddAppPlan'
import { useDispatch } from 'react-redux'
import { addExtension } from '../../Redux/Action/plugin.action'
import { ToastContainer } from 'react-toastify'
const AddPlugin = () => {
  const dispatch = useDispatch();
  const [moduleData, setModuleData] = useState({
    extension_name: "",
    short_description: '',
    extension_type: 'Featured',
    use_of_app: '',
    image: '',
    launched_date: '',
    languages: '',
    launched_date: new Date(),
    meta_title: '',
    meta_description: '',
    long_description: ''
  })
  const [enableSlug, setEnableSlug] = useState(false);
  const [err, setErr] = useState(false)
  const [credential, setCredential] = useState({
    key: '', value: '', is_required: false
  })
  const [slugField, setSlugField] = useState(false);
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (slug?.length) {
      setSlugField(true);
      setSlugName(await GenerateSlugName(slug));
    }
  };
  const [credentials, setCredentials] = useState([])
  const HandleCredentials = (e) => {
    e.preventDefault()
    if (!credential.key || !credential.value) return;
    setCredentials((prev) => [...prev, credential]);
    setCredential({ key: '', value: '', is_required: false });
  }
  // SAve Inputs
  const HandleInput = (e) => {
    setModuleData({ ...moduleData, [e.target.name]: e.target.value })
  }
  // Add Module
  const HandleSubmit = async (e) => {
    e.preventDefault()
    const body = { ...moduleData, extension_slug_name: slugName, credentials: credentials,available_plan:storePlan }
    try {
      dispatch(addExtension(body))
    } catch (error) {
      console.log(error)
    }
  }
  const handleEditorData = (value) => {
    setModuleData({ ...moduleData, long_description: JSON.stringify(value) });
  };
  // Image Functions
  const handleSetImage = (value) => {
    setModuleData({ ...moduleData, image: value });
  }
  const [isAddPlan, setIsAddPlan] = useState(false)
  const [isEditPlan, setIsEditPlan] = useState(false)
  const [editData, setEditData] = useState()
  const [storePlan, setStorePlan] = useState()
  const [editIndex, setEditIndex] = useState()
  const [deletIndex, setDeletIndex] = useState()
  const closeAddPlan = (value) => {
    setEditIndex()
    setEditData()
    setIsAddPlan(false);
    setIsEditPlan(false);
    setDeletIndex()
  }
  const handleStorePlan = (value) => {
    setStorePlan(value)
  }
  const columns = [
    {
      name: "Name",
      minWidth: "150px",
      cell: (row) => (
        <span className="line-clamp-1">{row.name}</span>
      ),
    },
    {
      name: "Type",
      minWidth: "150px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize">{row.plan_type}</span>
      ),
    },
    {
      name: "Price",
      minWidth: "150px",
      right: true,
      cell: (row) => (
        <span className="line-clamp-1 capitalize">₹ {row.price}</span>
      ),
    },
    {
      name: "Action",
      minWidth: "100px",
      right: true,
      cell: (row, index) => (
        <div className='flex items-center space-x-2'>
          <Tooltip title="Edit">
            <button onClick={(e) => { e.preventDefault(); handleEdit(row, index) }} className="line-clamp-1 capitalize"><Edit className="w-4 h-4 text-blue-500" /></button>
          </Tooltip>
          <Tooltip title="Remove">
            <button onClick={(e) => { e.preventDefault(); setDeletIndex(index); handleDelete(index) }} className="line-clamp-1 capitalize"><Trash2 className="w-4 h-4 text-red-500" /></button>
          </Tooltip>
        </div>
      ),
    },
  ]
  const handleEdit = (data, index) => {
    setEditIndex(index)
    setEditData(data)
    setIsAddPlan(true);
    setIsEditPlan(true);
  }
  const handleDelete = (id) => {
    setDeletIndex(id)
    const filterPlan = storePlan?.filter((x, index) => index !== id)
    setStorePlan(filterPlan)
  }
  return (
    <div className="w-full pb-[64px]">
      <div>
        <Breadcrumbs crumbs={[
          { name: 'Dashboard', path: `/dashboard` },
          { name: 'Apps', path: `/apps` },
          { name: 'Add App', path: `/add-app` },
        ]} />
        <h1 className='text-xl md:text-2xl font-semibold text-primary'>Add App</h1>
      </div>
      <div className="overflow-x-hidden h-full w-full">
        <form
          method="POST"
          className="space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto"
        >
          <div className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
            <div className="w-full">
              <label
                className="text-gray-700 text-sm font-medium mb-1"
                htmlFor="Title"
              >
                App Name
              </label>
              <input
                className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                type="text"
                name="extension_name"
                value={moduleData?.extension_name}
                onChange={HandleInput}
                placeholder='Module Title'
                onBlur={() => handleSlug(moduleData?.extension_name)}
              />
            </div>
            {slugField && (
              <div className="w-full">
                <label htmlFor="category_name">
                  app Slug name
                </label>
                <div
                  className={`flex items-center justify-between w-full border ${err && enableSlug
                    ? "border-red-500"
                    : "border-gray-300"
                    } rounded-[4px]`}
                >
                  <input
                    className="w-full  focus:outline-none"
                    type="text"
                    name="app_slug_name"
                    value={slugName}
                    disabled={!enableSlug}
                    onChange={(e) => setSlugName(e.target.value)}
                  />
                  {enableSlug ? (
                    <Check
                      onClick={async () => {
                        setSlugName(
                          await GenerateSlugName(
                            slugName
                          )
                        );
                        setEnableSlug(!enableSlug);
                        setErr(false);
                      }}
                      className="text-green-500 p-1 h-8 w-8"
                    />
                  ) : (
                    <Edit
                      onClick={() =>
                        setEnableSlug(!enableSlug)
                      }
                      className="text-blue-500 p-1 h-7 w-7"
                    />
                  )}
                </div>
                {err && enableSlug && (
                  <p className="text-xsm text-red-500">
                    Please save this changes
                  </p>
                )}
              </div>
            )}
            <div className="w-full">
              <label
                className="text-gray-700 text-sm font-medium mb-1"
                htmlFor="Title"
              >
                Use Of App
              </label>
              <input
                className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                type="text"
                name="use_of_app"
                value={moduleData?.use_of_app}
                onChange={HandleInput}
                placeholder='Ex. For Higher Conversations'
              />
            </div>
            <div className="w-full">
              <label
                className=" text-gray-700 text-sm font-medium mb-1"
                htmlFor="short_description"
              >
                Short Description
              </label>
              <textarea
                className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                type="text"
                id="short_description"
                name="short_description"
                value={moduleData?.short_description}
                onChange={HandleInput}
                placeholder='Short Description'
              />
            </div>
            <div className="w-full">
              <TextEditor
                label={"app description"}
                handleEditorData={handleEditorData}
              />
              <p className="text-xs mt-0 text-gray-400">
                of 5000 words allowed
              </p>
            </div>
            <div className="w-full">
              <label
                className=" text-gray-700 text-sm font-medium mb-1"
                htmlFor="short_description"
              >
                Language
              </label>
              <textarea
                className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                type="text"
                id="languages"
                name="languages"
                value={moduleData?.languages}
                onChange={HandleInput}
                placeholder='Enter languages'
              />
            </div>
            <div className="w-full">
              <label
                className="flex text-gray-700 text-sm font-medium mb-1"
                htmlFor="receiver"
              >
                App Type
              </label>
              <div className="relative">
                <select
                  value={moduleData?.extension_type}
                  name="extension_type"
                  onChange={HandleInput}
                  className="w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500"
                >
                  <option value='Featured'>Featured</option>
                  <option value='POS'>POS</option>
                  <option value='SMS'>SMS</option>
                  <option value='Payments'>Payments</option>
                  <option value='DMS'>DMS</option>
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
            <div className="w-full">
              <label>launched date</label>
              <label className="relative block w-full cursor-pointer">
                <DatePicker
                  className="border cursor-pointer w-full focus:border-gray-400 border-gray-300 outline-none"
                  selected={moduleData?.launched_date}
                  minDate={new Date()}
                  onChange={(date) => setModuleData({ ...moduleData, launched_date: date })}
                  selectsEnd
                  dateFormat="dd-MM-yyyy"
                />
                <span className="absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5">
                  <CalendarFill className="text-gray-600 w-3 h-3" />
                </span>
              </label>
            </div>
            <div className='w-full'>
              <label
                className=" text-gray-700 text-sm font-medium mb-1"
                htmlFor="short_description"
              >
                Credentials
              </label>
              <div className="w-full flex items-center mb-3 space-x-3">
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  id="key"
                  name="key"
                  value={credential.key}
                  onChange={(e) => setCredential({ ...credential, [e.target.name]: e.target.value })}
                  placeholder='Key  ex.AppID'
                />
                <div className="relative min-w-[200px]">
                  <select
                    className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                    type="text"
                    id="value"
                    name="value"
                    value={credential.value}
                    onChange={(e) => setCredential({ ...credential, [e.target.name]: e.target.value })}
                    placeholder='Value'
                  >
                    <option value=''>Select Input Type</option>
                    <option value='text'>Text</option>
                    <option value='number'>Number</option>
                    <option value='email'>Email</option>
                    <option value='integer'>Integer</option>
                  </select>
                  <div className="select-arrow"></div>
                </div>
                <label>
                  Required
                </label>
                <input
                  className="h-3.5 w-8 pr-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:border-gray-500 focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault'
                  name='is_required'
                  checked={credential.is_required}
                  value={credential.is_required}
                  onChange={(e) => setCredential({ ...credential, [e.target.name]: true })}

                />
                <button onClick={HandleCredentials} className=" secondary-btn text-white px-5 py-2 rounded-[4px] transition duration-300">Add</button>
              </div>
              {
                credentials?.map((elem, index) => {
                  return (
                    <div className="w-full flex items-center space-x-3 mb-3">
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                        type="text"
                        id="key"
                        name="key"
                        value={elem.key}
                        placeholder='Key  ex.AppID'
                      />
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                        type="text"
                        id="value"
                        name="value"
                        value={elem.value}
                        placeholder='Key  ex.AppID'
                      />
                      <label>
                        Required
                      </label>
                      <input
                        className="h-3.5 w-8 pr-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:border-gray-500 focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                        type='checkbox'
                        role='switch'
                        checked={elem?.is_required === true}
                        name='required'
                        onChange={() => {
                          const updatedCredentials = [...credentials];
                          updatedCredentials[index].is_required = !elem.is_required;
                          setCredentials(updatedCredentials);
                        }}
                      />
                      <div className="min-w-[50px] text-center" >
                        <Trash2 onClick={() => setCredentials(credentials.filter((x, v) => v !== index))} className="w-[18px] h-[18px] cursor-pointer inline-block hover:text-black transition-all duration-150 text-gray-500" />
                      </div>
                    </div>
                  )

                })
              }
            </div>
          </div>
          <details
            open
            className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]"
          >
            <summary className="text-base flex items-center justify-between font-medium text-black">
              <div>
                <span>Media</span>
                <p className="text-xs text-gray-500 font-normal">
                  Upload captivating image to make your
                  app stand out.
                </p>
              </div>
              <span className="transition group-open:rotate-180">
                <ChevronDown className="text-black w-4 h-5" />
              </span>
            </summary>
            <ImageUploader
              alreadyImage={moduleData?.image} handleSetImage={handleSetImage} folder="plugin" format="image"
            />
          </details>
          <details className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]">
            <summary
              htmlFor="Meta Tag Title"
              className="text-base flex items-center justify-between font-medium text-black"
            >
              <div>
                <span>Plans</span>
                <p className="text-xs text-gray-500 font-normal">
                  Add a title and description to see how
                  this product might appear in a search
                  engine listing
                </p>
              </div>
              <span className="transition group-open:rotate-180">
                <ChevronDown className="text-black w-4 h-5" />
              </span>
            </summary>
            {
              storePlan?.length > 0 ?
                <div className='text-center'>
                  <DataTable columns={columns} data={storePlan} />
                  <button onClick={(e) => { e.preventDefault(); setIsAddPlan(true) }} className='text-link font-medium inline-flex mt-4 items-center text-sm justify-center hover:underline'><PlusCircle className="w-4 h-4 inline-block me-1" />Add More Plan</button>
                </div>
                :
                <div className='text-center border-t pt-4'>
                  <button className='bulk-btn' type='button' onClick={(e) => { e.preventDefault(); setIsAddPlan(true) }}>Add pricing plans</button>
                </div>
            }
          </details>
          <details className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]">
            <summary
              htmlFor="Meta Tag Title"
              className="text-base flex items-center justify-between font-medium text-black"
            >
              <div>
                <span>Search engine listing</span>
                <p className="text-xs text-gray-500 font-normal">
                  Add a title and description to see how
                  this product might appear in a search
                  engine listing
                </p>
              </div>
              <span className="transition group-open:rotate-180">
                <ChevronDown className="text-black w-4 h-5" />
              </span>
            </summary>
            <div className="mb-4">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="Meta Tag Title"
                  className="flex items-center justify-between"
                >
                  Meta Title
                </label>
              </div>
              <input
                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                type="text"
                id="Meta Tag Title"
                name="meta_title"
                value={moduleData?.meta_title}
                onChange={(e) => { setModuleData({ ...moduleData, meta_title: e.target.value }) }}
                placeholder="Enter meta title"
              />
            </div>

            <div className="mb-4">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="Meta Tag description"
                  className="flex items-center justify-between"
                >
                  Meta Description
                </label>
              </div>
              <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                type="text"
                id="Meta Tag description"
                name="meta_description"
                value={moduleData?.meta_description}
                onChange={(e) => { setModuleData({ ...moduleData, meta_description: e.target.value }) }}
                placeholder="Enter meta description"
              />
            </div>
          </details>
          <div className="flex items-center justify-between w-full">
            <Link
              to={`/apps`}
              className="w-fit flex text-link font-medium hover:underline text-sm items-center "
            >
              <ArrowLeft className="w-3 mr-1" />
              Go to apps
            </Link>
            <button
              className={"inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"}
              type="button"
              onClick={(e) => HandleSubmit(e)}
            >
              Add New App
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
      <AddAppPlan isAddPlan={isAddPlan} closeAddPlan={closeAddPlan} handleStorePlan={handleStorePlan} type={isEditPlan ? "Edit" : "Add"} editData={editData} editIndex={editIndex} deletIndex={deletIndex}/>
    </div>
  )
}

export default AddPlugin