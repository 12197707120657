import {
    Autocomplete,
    Skeleton,
    TextField
} from "@mui/material";
import {
    ArrowLeft,
    Check,
    ChevronDown,
    Edit,
    X,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GenerateSlugName } from "../../../controllers/GenerateSlugName";
import ImageUploader from "../../../controllers/ImageUploader";
import TextEditor from "../../../controllers/TextEditor";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import Notification from "../../Notification";
import { useDispatch, useSelector } from "react-redux";
import { getPerticularSEBlog, getSEBlogCategorys, updateSEBlog } from "../../../Redux/Action/website.action";
import { ToastContainer } from "react-toastify";

const UpdateWebsiteBlog = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { perticularBlog, blogCategorys } = useSelector((state) => state.websiteReducer);
    const [warn, setwarn] = useState(false);
    const [elem, setElem] = useState();
    // image upload logic
    const [image, setimage] = useState();
    const [meta, setMeta] = useState({ meta_title: "", meta_description: "" });
    const { meta_description, meta_title } = meta;
    const [SelectCategory, setSelectCategory] = useState();
    const [secondDescription, setSecondDescription] = useState();
    const [title, setTitle] = useState();
    const [sortOrder, setSortOrder] = useState();
    const [err, setErr] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    const handleSetImage = (value) => {
        setimage(value);
    };
    const [formSpinner, setFormSpinner] = useState(true);
    const getPerticularBlog = async () => {
        setFormSpinner(true);
        try {
            dispatch(getPerticularSEBlog(params?.blog_slug_name))
        } catch (error) {
            console.log(error);
            setFormSpinner(false);
        }
    };
    useEffect(() => {
        getPerticularBlog();
    }, [params.blog_slug_name]);

    useEffect(() => {
        if(perticularBlog) {
            setElem(perticularBlog?.data);
            setimage(perticularBlog?.data?.image);
            setSelectCategory(
                perticularBlog?.data?.blogcategory ? perticularBlog?.data?.blogcategory : []
            );
            setMeta({
                meta_title: perticularBlog?.data?.meta_title || "",
                meta_description: perticularBlog?.data?.meta_description || "",
            });
            setSortOrder(perticularBlog?.data?.sort_order || "");
            setSecondDescription(
                perticularBlog?.data?.description ? `${perticularBlog?.data?.description}` : ""
            );
            setTitle(perticularBlog?.data?.title ? `${perticularBlog?.data.title}` : "");
            setSlugName(
                perticularBlog?.data?.blog_slug_name ?? ""
            );
            setBlogTagsArray(perticularBlog?.data?.blog_tags);
            setFormSpinner(false);
        }
    },[perticularBlog])

    // get Store Category
    // const [StoreCategory, setStoreCategory] = useState([]);
    // const GetStoreCategory = async () => {
    //     try {
    //         const ApiCall = await API({ url: `/super/admin/storecategory/getStoreCategory`, method: 'get' });
    //         setStoreCategory(ApiCall?.data?.data?.categorys);
    //     } catch (error) {
    //         setIsNotification(true);
    //         setNotificationMsg(error);
    //         setSeverity("error");
    //     }
    // };
    // useEffect(() => {
    //     GetStoreCategory();
    // }, []);

    // get Parents Category
    const [Category, setCategory] = useState([]);
    const GetCategory = async () => {
        try {
            dispatch(getSEBlogCategorys());
        } catch (error) {
            console.log(error);
        }
    };
    const [slugName, setSlugName] = useState();
    useEffect(() => {
        GetCategory();
    }, []);

    useEffect(() => {
        if(blogCategorys) {
            setCategory(blogCategorys);
        }
    },[blogCategorys])

    const handleBlogCategory = (id, name) => {
        if (id) {
            const cat = SelectCategory.find((x) => x._id === id);
            if (!cat) {
                setSelectCategory([
                    ...SelectCategory,
                    { _id: id, blogcategory_name: name },
                ]);
            }
        }
    };
    const HandleUpdate = async (e, key) => {
        e.preventDefault();
        try {
            let body = {
                blogcategory: SelectCategory?.map((x) => x._id),
                // blog_tags: blogTagsArray,
                title: title,
                image: image,
                description: secondDescription,
                blog_slug_name: slugName,
                sort_order: sortOrder,
                blog_tags: blogTagsArray?.filter((x) => x !== ""),
                ...meta,
            }
            if (key === "draft") {
                body = {
                    ...body,
                    is_draft: true
                }
            } else {
                body = {
                    ...body,
                    is_draft: false
                }
            }
            dispatch(updateSEBlog(elem._id, body));
        } catch (error) {
            console.log(error);
        }
    };
    const [blogTagsValue, setBlogTagsValue] = useState([]);
    const [blogTagsArray, setBlogTagsArray] = useState([]);

    // save Group Values In Array
    const SaveblogTagsValue = () => {
        if (blogTagsValue.length > 0) {
            if (blogTagsValue.includes(",")) {
                let TagSeperator = blogTagsValue.split(",");

                let finalArray = [];
                let productTags = [...blogTagsArray, ...TagSeperator];
                productTags.filter((tag) => {
                    const searchRegex = new RegExp(`^${tag.trim()}$`, "i");
                    let exists = finalArray.some((finalTag) =>
                        searchRegex.test(finalTag)
                    );
                    if (!exists) {
                        finalArray.push(tag.trim());
                    }
                });
                setBlogTagsArray(finalArray);
            } else {
                const searchRegex = new RegExp(`^${blogTagsValue}$`, "i");

                const exists = blogTagsArray.some((tag) =>
                    searchRegex.test(tag)
                );

                if (!exists) {
                    setBlogTagsArray((prev) => [...prev, blogTagsValue]);
                }
            }
            setBlogTagsValue("");
        }
    };

    // remove Tag
    const RemoveTag = (index) => {
        if (index > -1) {
            // only splice array when item is found
            blogTagsArray.splice(index, 1); // 2nd parameter means remove one item only
        }
        setBlogTagsArray([...blogTagsArray]);
    };

    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const removeCategory = (id) => {
        const removeRelated = SelectCategory.filter((x) => x._id !== id);
        setSelectCategory(removeRelated);
    };
    const handleEditorData = (value) => {
        setSecondDescription(value)
    }
    const [enableSlug, setEnableSlug] = useState(false);
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugName(await GenerateSlugName(slug));
        }
    };
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}

            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary">
                    Update website blog - {elem?.title}
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="space-y-4 w-[90%] lg:w-[80%] mx-auto"
                    >
                        {formSpinner ? (
                            <div className="space-y-4">
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div className="flex items-center space-x-4 w-full">
                                        <div className="w-full">
                                            <Skeleton
                                                className="w-[200px] mb-1"
                                                variant="rounded"
                                            />
                                            <Skeleton
                                                className="w-full min-h-[45px]"
                                                variant="rounded"
                                            />
                                        </div>
                                        <div className="w-full">
                                            <Skeleton
                                                className="w-[200px] mb-1"
                                                variant="rounded"
                                            />
                                            <Skeleton
                                                className="w-full min-h-[45px]"
                                                variant="rounded"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[45px]"
                                            variant="rounded"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[200px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div>
                                        Media
                                        <p className="text-xs text-gray-500 font-normal">
                                            Upload captivating image to make
                                            your product stand out.
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[130px]"
                                            variant="rounded"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[130px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="bg-white group border border-gray-300  space-y-4 rounded-xl p-[25px]">
                                    <div className="w-full mb-4">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                Blog Title
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            name="title"
                                            // maxLength={60}
                                            value={title}
                                            onChange={(e) => {
                                                setTitle(e.target.value);
                                                setwarn(false);
                                            }}
                                            placeholder="e.g. Tech"
                                            onBlur={() => handleSlug(title)}
                                        // onBlur={async () => setSlugName(await GenerateSlugName(title))}
                                        />
                                        {warn && (
                                            <h1 className="text-sm text-red-500">
                                                Please enter blog title
                                            </h1>
                                        )}
                                    </div>
                                    {/* <div className="w-full mb-4">
                                        <label
                                            className="flex items-center justify-between"
                                            htmlFor="category_name"
                                        >
                                            blog Slug name
                                        </label>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            id="blog_slug_name"
                                            name="blog_slug_name"
                                            value={slugName}
                                            disabled
                                            // onChange={(e) => setSlugName(e.target.value)}
                                            // onBlur={async (e) => setSlugName(await GenerateSlugName(e.target.value))}
                                        />
                                    </div> */}

                                        <div className="w-full">
                                            <label htmlFor="category_name">
                                                blog Slug name
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </label>
                                            <div
                                                className={`flex items-center justify-between w-full border ${err && enableSlug
                                                    ? "border-red-500"
                                                    : "border-gray-300"
                                                    } rounded-[4px]`}
                                            >
                                                <input
                                                    className="w-full  focus:outline-none"
                                                    type="text"
                                                    name="blog_slug_name"
                                                    value={slugName}
                                                    disabled={!enableSlug}
                                                    onChange={(e) =>
                                                        setSlugName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {enableSlug ? (
                                                    <Check
                                                        onClick={async () => {
                                                            if(slugName?.length > 0) {
                                                                setSlugName(
                                                                    await GenerateSlugName(
                                                                        slugName
                                                                    )
                                                                );
                                                                setEnableSlug(
                                                                    !enableSlug
                                                                );
                                                                setErr(false);
                                                            }
                                                        }}
                                                        className="text-green-500 p-1 h-8 w-8"
                                                    />
                                                ) : (
                                                    <Edit
                                                        onClick={() =>
                                                            setEnableSlug(
                                                                !enableSlug
                                                            )
                                                        }
                                                        className="text-blue-500 p-1 h-7 w-7"
                                                    />
                                                )}
                                            </div>
                                            {err && enableSlug && (
                                                <p className="text-xsm text-red-500">
                                                    Please save this changes
                                                </p>
                                            )}
                                        </div>

                                    <div className="mb-4">
                                        <label htmlFor="description">
                                            Blog category
                                        </label>
                                        <Autocomplete
                                            className="autocomplete"
                                            options={
                                                Category?.data
                                                    ? Category?.data?.records
                                                    : []
                                            }
                                            getOptionLabel={(category) =>
                                                category.blogcategory_name
                                            }
                                            onChange={(e, v) =>
                                                v
                                                    ? handleBlogCategory(
                                                        v?._id,
                                                        v?.blogcategory_name
                                                    )
                                                    : ""
                                            }
                                            // value={Category?.data?.find((category) => category.blogcategory_name === elem?.blogcategory?.blogcategory_name) || null}
                                            renderInput={(Categories) => (
                                                <TextField
                                                    placeholder="Select blog category"
                                                    className="cursor-pointer"
                                                    defaultValue={
                                                        elem?.blogcategory
                                                            ?.blogcategory_name
                                                    }
                                                    {...Categories}
                                                />
                                            )}
                                        />
                                        {SelectCategory?.map((elem, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    className="bg-[#f5f5f5] mt-1 inline-flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded"
                                                >
                                                    {elem?.blogcategory_name}
                                                    <X
                                                        className="h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer"
                                                        onClick={() =>
                                                            removeCategory(
                                                                elem?._id
                                                            )
                                                        }
                                                    />
                                                </span>
                                            );
                                        })}
                                    </div>
                                    <div className="mb-4">
                                        <TextEditor label={'blog description'} handleEditorData={handleEditorData} defaultValue={secondDescription} />
                                        {err &&
                                            secondDescription?.length === 0 && (
                                                <p className="text-red-500 text-xs">
                                                    Please enter description for
                                                    generate meta data
                                                </p>
                                            )}
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex items-center justify-between">
                                            <label htmlFor="Description">
                                                Blog Tags
                                            </label>
                                            <p className="text-gray-500 font-normal text-xs">
                                                Use{" "}
                                                <b className="text-black">
                                                    Enter
                                                </b>{" "}
                                                key to separate the tags
                                            </p>
                                        </div>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            id="blog_tags"
                                            name="blog_tags"
                                            value={blogTagsValue}
                                            onChange={(e) =>
                                                setBlogTagsValue(e.target.value)
                                            }
                                            onKeyDown={(e) =>
                                                e.key === "Enter"
                                                    ? SaveblogTagsValue()
                                                    : ""
                                            }
                                            placeholder="e.g. Black"
                                        />
                                        <div className="flex mt-1 flex-wrap items-center">
                                            {blogTagsArray &&
                                                blogTagsArray.map(
                                                    (elem, index) => {
                                                        return (
                                                            <span
                                                                key={index}
                                                                className="bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded"
                                                            >
                                                                {elem}
                                                                <X
                                                                    className="h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer"
                                                                    onClick={() =>
                                                                        RemoveTag(
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </span>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <details
                                    open
                                    className="bg-white cursor-pointer group border border-gray-300  space-y-4 rounded-xl p-[25px]"
                                >
                                    <summary className="text-base flex items-center justify-between font-medium text-black">
                                        <div>
                                            <span>Media</span>
                                            <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            <p className="text-xs text-gray-500 font-normal">
                                                Upload captivating image to make
                                                your category stand out.
                                            </p>
                                        </div>
                                        <span className="transition group-open:rotate-180">
                                            <ChevronDown className="text-black w-4 h-5" />
                                        </span>
                                    </summary>
                                    <ImageUploader
                                        alreadyImage={image}
                                        handleSetImage={handleSetImage}
                                        folder="blog"
                                        format="image"
                                    />
                                </details>
                                <details
                                    open
                                    className="bg-white cursor-pointer group border border-gray-300  space-y-4 rounded-xl p-[25px]"
                                >
                                    <summary className="text-base flex items-center justify-between font-medium text-black">
                                        <div>
                                            <span>Search engine listing</span>
                                            <p className="text-xs text-gray-500 font-normal">
                                                Add a title and description to
                                                see how this product might
                                                appear in a search engine
                                                listing
                                            </p>
                                        </div>
                                        <span className="transition group-open:rotate-180">
                                            <ChevronDown className="text-black w-4 h-5" />
                                        </span>
                                    </summary>
                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <div className="flex items-center justify-between">
                                                <label
                                                    htmlFor="Meta Tag Title"
                                                    className="flex items-center justify-between"
                                                >
                                                    Meta title
                                                </label>
                                                {/* <span
                            className='inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300'
                            onClick={() => {
                              generateMetaData(description, 'title');
                            }}
                          >
                            Get meta title
                          </span> */}
                                            </div>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="text"
                                                id="Meta Tag Title"
                                                name="meta_title"
                                                value={meta_title}
                                                onChange={(e) => {
                                                    setMeta({
                                                        ...meta,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                }}
                                                placeholder="Enter meta title"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <div className="flex items-center justify-between">
                                                <label
                                                    htmlFor="Meta Tag description"
                                                    className="flex items-center justify-between"
                                                >
                                                    Meta description
                                                </label>
                                                {/* <span
                            className='inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300'
                            onClick={() => {
                              generateMetaData(description, 'description');
                            }}
                          >
                            Get meta description
                          </span> */}
                                            </div>
                                            <textarea
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="text"
                                                id="Meta Tag description"
                                                name="meta_description"
                                                value={meta_description}
                                                onChange={(e) => {
                                                    setMeta({
                                                        ...meta,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                }}
                                                placeholder="e.g. We have best fashionable products"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="sort_order">
                                                sort order
                                            </label>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="number"
                                                id="sort_order"
                                                name="sort_order"
                                                value={sortOrder}
                                                onChange={(e) => {
                                                    setSortOrder(
                                                        e.target.value <= 0
                                                            ? 0
                                                            : e.target.value
                                                    );
                                                }}
                                                placeholder="Enter sort order"
                                            />
                                        </div>
                                    </div>
                                </details>
                                <div className="flex items-center justify-between w-full">
                                    <Link
                                        to={`/site-data/blog`}
                                        className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                                    >
                                        <ArrowLeft className="w-3 mr-1" />
                                        Go to blogs
                                    </Link>
                                    <div className="flex items-center space-x-2">
                                        {
                                            elem?.is_draft === true &&
                                            <button
                                                className={
                                                    secondDescription && title && image?.length && slugName
                                                    ? "inline-block secondary-btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                                    : "font-medium text-sm text-link border-primary px-5 py-2 rounded border opacity-40 bg-white cursor-not-allowed"
                                                }
                                                type="button"
                                                disabled={title?.length <= 0 ||
                                                    !image?.length ||
                                                    secondDescription?.length <=0 ||
                                                    slugName?.length <= 0}
                                                onClick={(e) => HandleUpdate(e, "draft")}
                                            >
                                                Update Draft
                                            </button>
                                        }
                                        <button
                                            className={
                                                secondDescription && title && image?.length && slugName
                                                    ? "inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                                    : "font-medium text-sm text-white opacity-40 btn cursor-not-allowed"
                                            }
                                            type="button"
                                            onClick={(e) => HandleUpdate(e, "publish")}
                                            disabled={title?.length <= 0 ||
                                                !image?.length ||
                                                secondDescription?.length <=0 ||
                                                slugName?.length <= 0}
                                        >
                                            {elem?.is_draft === true ? "Publish Blog" : "Update Blog"}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                </div>
                <ToastContainer />
            </div>
        </>
    );
};

export default UpdateWebsiteBlog;
